import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux'
import { addPostingLink, getOnePage, getStudioUriById, updatePostingLink } from "../../services";
import {
  update as updateStudioInStore,
} from '../../store/studios'
import { FaSpinner } from "react-icons/fa";
import { BiMinus, BiPlusMedical } from "react-icons/bi";
import { PlusIcon, TrashIcon } from "../../assets/icons/session";
import { FiTrash } from "react-icons/fi";

export default ({ studio, show, onHide, postingLink = null }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(null)
  const [showUrl, setShowUrl] = useState(false)
  const [externalPages, setExternalPages] = useState([{posting_page_name:'', posting_page_id:'', studio_uri:'', url:''}])

  const dispatch = useDispatch()

  useEffect(() => {
    if(postingLink){
      setSelectedIds(postingLink.posting_pages.map((page) => page._id));
    }
    if(postingLink?.other_posting_pages && postingLink.other_posting_pages.length > 0){
      setExternalPages(postingLink.other_posting_pages)
      setShowUrl(true)
    }
  }, [postingLink, show])

  const onSubmit = async(e) => {
    e.preventDefault();

    const selectedPages = studio.postingPages.filter((page) => selectedIds.includes(page._id));

    const formData = new FormData(e.target);
    const name = formData.get("name");

    const _studio = { ...studio }
    let other_posting_pages = []
   
    const filteredExternalPages = externalPages.filter((page) => page.posting_page_id)

    let postinLinkObject = {
      name,
      posting_pages: selectedPages,
      other_posting_pages: filteredExternalPages
    }
    if(postingLink){
      updatePostingLink(postinLinkObject, studio._id, postingLink._id).then(res=>{
        _studio.posting_links = res.posting_links
        dispatch(updateStudioInStore(_studio))
      })
    } else{
      addPostingLink(postinLinkObject, studio._id).then(res=>{
        _studio.posting_links = res.posting_links
        dispatch(updateStudioInStore(_studio))
      })
    }
    
    onHide();
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (value === "all-posting-pages") {
      if (checked) {
        let selectedPages = []; 
        studio.postingPages.map((postingPage) => {
          selectedPages.push(postingPage._id);
        });
        setSelectedIds([...selectedPages]);
      } else {
        setSelectedIds([]);
      }
    } else {
      if (checked) {
        setSelectedIds([...selectedIds, value]);
      } else {
        setSelectedIds(
          selectedIds.filter((it) => it !== value)
        );
      }
    }
  }

  const addPage = () => {
    setExternalPages([...externalPages, {posting_page_name:'', posting_page_id:'', studio_uri:'', url:''}])
  }

  const removeExternalPage = (index) => {
    const _temp = [...externalPages]
    _temp.splice(index,1)
    setExternalPages(_temp)
  }

  const handleUrl = async(value,index) => {
    try{
      if(!value){
        const _temp = [...externalPages]
        _temp[index] = {posting_page_name:'', posting_page_id:'', studio_uri:''}
        setExternalPages(_temp)
        return
      }
      setLoading(index)
      const lastPart = value.substring(value.lastIndexOf('/') + 1);
      const response = await getOnePage(lastPart)

     
      const otherPostingPageUri = await getStudioUriById(response.studio)
      if(response.name){
        const otherPostingPage = {
          posting_page_name: response.name,
          posting_page_id: response._id,
          studio_uri: otherPostingPageUri.uri,
        }
        const _temp = [...externalPages]
        _temp[index] = otherPostingPage
        setExternalPages(_temp)
      }else{
        const _temp = [...externalPages]
        _temp[index] = {posting_page_name:'Not Found', posting_page_id:'', studio_uri:''}
        setExternalPages(_temp)
      }

    }catch(e){
      console.log('handleUrl error :>> ', e);
    }finally{
      setLoading(null)
    }
  }

  return (
    <Modal show={show} onHide={onHide} className="posting-link-modal">
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton className="align-items-baseline">
          <h4 className="mb-0 mr-3">{postingLink ? 'Edit Posting Link': 'Add Posting Link'}</h4>
        </Modal.Header>

        <Modal.Body>
          <span className="h-14-600 mb-2">Name</span>
          <Form.Control
            defaultValue={postingLink ? postingLink.name : ""}
            type="text"
            className="form-control mb-3"
            name="name"
            required
          />
          <div>
            <span className="h-14-600">Choose posting Pages</span>
            <div className="d-flex align-item-center my-3">
              <input
                id="all"
                type="checkbox"
                value="all-posting-pages"
                onChange={handleCheckboxChange}
                checked={studio.postingPages.every(page => selectedIds.includes(page._id))}/>
              <label className="ml-2 mb-0" htmlFor="all">All Posting Pages</label>
            </div>
            <div className="horizontal-line"></div>
            <div className="my-3 posting-page-checkbox-container">
              {studio.postingPages.map((postingPage) => {
                return (
                  <div
                    key={postingPage._id}
                    className="d-flex align-item-center"
                  >
                    <input
                      id={postingPage._id}
                      type="checkbox"
                      value={postingPage._id}
                      onChange={handleCheckboxChange}
                      checked={selectedIds.includes(postingPage._id)}/>
                    <label className="ml-2 mb-0" htmlFor={postingPage._id}>{postingPage.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <span className="h-16-600">Choose posting Page from another Project</span>
              <button type='button' onClick={() => setShowUrl(!showUrl)} className="ml-2 action-button">
                {!showUrl ? <BiPlusMedical /> :
                 <BiMinus/>}
              </button>
            </div>
            {
              showUrl ? (
                <div  className="px-2 external-item-container" >
                  {externalPages.map((item,index) => {
                    return(
                      <div className="mt-2 d-flex align-items-start external-item" key={item.posting_page_id}>
                        <div style={{flex:1}}>
                          <div className="">
                            <span className="h-12-600">URL</span>
                            <Form.Control
                              type="text"
                              className="form-control mb-2 h-12-400"
                              name="posting-page-url"
                              onChange={(e) => handleUrl(e.target.value, index)}
                              defaultValue={item.studio_uri ? `${window.location.href}/${item.studio_uri}/${item.posting_page_id}` : ''}
                            />
                          </div>
                          <div>
                            <span className="h-12-600">Name {loading === index ? <FaSpinner size='10px' className='spinning' /> :null}</span>
                              <Form.Control
                                value={item.posting_page_name}
                                type="text"
                                className="form-control h-12-400"
                                disabled
                              />
                          </div>
                        </div>
                        <button 
                          className="ml-2 action-button"
                          style={{padding:'5px', marginTop:'23px'}}
                          onClick={() =>removeExternalPage(index)}
                          title="Remove Page"><FiTrash/></button>
                      </div>
                    )
                  })}
                </div>
              ) 
              : null
            }
            {showUrl && <button type="button" onClick={addPage} className="mt-2 action-button"><PlusIcon/>Add another Page</button>}
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="primary-solid-button h-14-600 text-white"
            disabled={selectedIds.length === 0 || loading}>{postingLink ? 'Edit Posting Link' : 'Add Posting Link'}</button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
