import React from "react";
import { Modal } from "react-bootstrap";

import { CheckedIcon } from "../../../assets/icons/session";
import useConfirmAudition from "../hooks/use-confirm-audition";
import useProfile from "../../TalentPage/hooks/use-profile";
import "./ConfirmAuditionModal.scss";

const ConfirmAuditionModal = ({
  showModal,
  setShowModal,
  auditionData:{
    session_id,
    day_id,
    slot,
    role,
    date,
  },
  clearAuditionData,
  currentDate
}) => {
  const {auditionDate, appointmentStatus,showConfirmedMessage,confirmAudition, setShowConfirmedMessage, cleanData, loadingConfirm} = useConfirmAudition({
    session_id,
    day_id,
    slot,
    role,
    date,
    clearAuditionData,
    currentDate
  });

  const { timezoneAbbr } = useProfile()

  const closeModal = () => {
    setShowModal(false);
    setShowConfirmedMessage(false);
    cleanData()
  }

  return (
    <>
      {showConfirmedMessage ? (
        <Modal
          show={showModal}
          onHide={closeModal}
          className="confirmed-modal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <span className="topic h-20-600">
                <CheckedIcon /> Appointment Confirmed
              </span>
              <div className="h-14-400 mt-2 body-text">
                Check your email for audition details and the session check-in
                link.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-container">
              <button
                className="h-14-700 confirm-btn"
                onClick={closeModal}
              >
                Okay
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          show={showModal}
          onHide={closeModal}
          className="confirm-modal"
          centered
        >
          <Modal.Header closeButton>
            <h5 className="mb-0 h-20-600">Confirm Audition</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <div>{`${appointmentStatus?.message? `${appointmentStatus?.type}: ${appointmentStatus?.message}`: ""}`}</div>
              <span className="h-14-600">
                Confirm these details for your audition
              </span>
              <div className="content-container">
                <div className="detail-item">
                  <span className="h-12-600">Role</span>
                  <span className="h-14-400">{role}</span>
                </div>
                <div className="detail-item">
                  <span className="h-12-600">Date</span>
                  <span className="h-14-400">{auditionDate}</span>
                </div>
                <div className="detail-item">
                  <span className="h-12-600">Time</span>
                  <span className="h-14-400">{slot?.start} ({timezoneAbbr})</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-container">
              <button
                className="h-14-700 cancel-btn"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="h-14-700 confirm-btn"
                onClick={() => {
                  confirmAudition();
                }}
              >
                {loadingConfirm ? 'Confirming...' : 'Confirm'}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ConfirmAuditionModal;
