import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudioByUri } from "../../services";
import "./style.scss";
import SendPostingLinkEmailModal from "../Studio/SendPostingLinkEmailModal";
import { FaSpinner } from "react-icons/fa";

const PostingLinkPage = (props) => {
  const studio_uri = props.match.params.uri;
  const posting_link_id = props.match.params.postinglink_id;
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [studio, setStudio] = useState({});
  const [postingLink, setPostingLink] = useState({});
  const [loading, setLoading] = useState(false);

  const host = window.location.origin
  const currentLink =`${host}/posting-link-page/${studio_uri}/${postingLink._id}`
  if(postingLink.name && studio.name){
    document.title = `${postingLink.name} - ${studio.name} - Posting Links`;
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const _studio = await getStudioByUri(studio_uri);
      setStudio(_studio);
      const _postingLink = _studio.posting_links.find(
        (link) => link._id === posting_link_id
      );
      if (_postingLink.other_posting_pages && _postingLink.other_posting_pages.length > 0) {
        const updatedPages = await Promise.all(
          _postingLink.other_posting_pages.map(async (page) => {
            const studio = await getStudioByUri(page.studio_uri);
            return { ...page, studio_name: studio.name };
          })
        );
  
        _postingLink.other_posting_pages = updatedPages;
      }
      setPostingLink(_postingLink);
      setLoading(false);
    };
    fetchData();
  }, [studio_uri, posting_link_id]);
  
  if(loading) {
    return (
      <div className={`loading show`}>
        <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
          <FaSpinner size='32px' className='spinning' />
          <span className='h4'>Processing...</span>
        </div>
      </div>
    )
  }
  return (
    <div className="posting-link-page">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <h1 className="h-32-400 mr-2 mt-2 mb-0">{postingLink.name}</h1>
          <span className="h-14-600 secondary-color">{studio.name}</span>
        </div>
        <div className="d-flex align-items-center">
          <button className="action-button mx-2 h-12-600" onClick={() =>  navigator.clipboard.writeText(currentLink)}>Copy Link</button>
          <button className="action-button h-12-600" onClick={() => setShowEmailModal(true)}>Share Link</button>
        </div>
      </div>
      <div className="posting-page-item-container mt-4">
        {postingLink.posting_pages &&
          postingLink.posting_pages.map((page) => {
            return (
              <div key={page._id} className="posting-page-item">
                <h4 className="h-14-600 ls-2">{page.name}</h4>
                <Link
                  to={`/posting-page-via-link/${studio_uri}/${postingLink._id}/${page._id}`}
                  className="action-button h-12-600"
                  target="_blank"
                >
                  View Page
                </Link>
              </div>
            );
          })}
      </div>
      <div className="posting-page-item-container mt-4">
        {postingLink?.other_posting_pages && <span className="h-12-600 secondary-color mt-4 mb-2 pl-2">FROM OTHER PROJECTS</span>}
        {postingLink?.other_posting_pages &&
          postingLink.other_posting_pages.map((otherPage) => {
            return (
              <div key={otherPage._id} className="posting-page-item">
                <div>
                  <h4 className="h-14-600 ls-2">{otherPage.posting_page_name}</h4>
                  <span className="h-10-600 text-secondary">{otherPage.studio_name}</span>
                </div>
                <Link
                  to={`/posting-page-via-link/${otherPage.studio_uri}/${postingLink._id}/${otherPage.posting_page_id}`}
                  className="action-button h-12-600"
                  target="_blank"
                >
                  View Page
                </Link>
              </div>
            );
          })}
      </div>
       
      <SendPostingLinkEmailModal
        show={showEmailModal}
        onHide={() => {
          setShowEmailModal(null)
        }}
        studio={studio}
        emailSessionParams={postingLink}
        emailSessionLink={`${host}/posting-link-page/${studio_uri}/${postingLink._id}`}
      />
    </div>
  );
};

export default PostingLinkPage;
