import React from "react";
import { Modal } from "react-bootstrap";
import CreatePostingPage from "../../views/Studio/CreatePostingPage";
import { USER_TYPE, VIDEO_REVIEW_PERMISSIONS } from "../../constants";

const CopyModal = ({
  showPageCopyModal,
  setShowPageCopyModal,
  studio,
  postingPages,
  newPostingPage,
  limeliteChecked = false,
  setLimeLiteChecked = null,
  setNewPostingPage,
  handleGroupsCopy,
}) => {
  return (
    <Modal
      show={showPageCopyModal}
      onHide={() => {
        setShowPageCopyModal(false);
        if(setLimeLiteChecked){
          setLimeLiteChecked(false);
        }
      }}
    >
      <Modal.Header closeButton>
        <h5 className="mb-0">Copy selected group</h5>
      </Modal.Header>
      <Modal.Body>
        <CreatePostingPage
          postingPages={postingPages}
          newPostingPage={newPostingPage}
          setNewPostingPage={setNewPostingPage}
        />
       
          <div>
            <label className="d-flex align-items-center mt-2">
              <input id="send-audition-link" type="checkbox" className="mr-2" />
              Send link to talent via email?
            </label>
          </div>
  
        {VIDEO_REVIEW_PERMISSIONS.CAN_COPY_TO_LIMELITE() &&
          studio?.limelite_project_id && (
            <div className="folder-selector-container">
              <label
                className="d-flex align-items-center mt-2"
                htmlFor="copy-to-limelite"
              >
                <input
                  id="copy-to-limelite"
                  type="checkbox"
                  className="mr-2"
                  checked={limeliteChecked}
                  onChange={() => setLimeLiteChecked(!limeliteChecked)}
                />
                Copy to Limelite
              </label>
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={!newPostingPage?.name}
          className="btn btn-primary"
          onClick={handleGroupsCopy}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyModal;
